import { Fragment } from "react";
import Full from './full/Full';
import './App.scss';

function App() {
  return <Fragment>
        <div className='header-wrapper'>
            <a className='header' href={process.env.PUBLIC_URL + "/#full-directions"}>
                <img src={process.env.PUBLIC_URL + "/logo-uno-trading-corporation.svg"} className="logo" alt="UNO Trading Corp." />
            </a>
        </div>
        <Full />
    </Fragment>;
}

export default App;
