export function getInterpolators() {
    function interpolatorSmoothScaleUpAndDown(proportion, inTransformLevel=1, outTransformLevel=1) {
        var styles = {};
        if (proportion < 0) {
            
            if (inTransformLevel < 0) {
                styles.opacity = 1;
            } else if (inTransformLevel >= 0) {
                styles.opacity = 0;
                styles.transform =`scale(${4})`;
            }
            return styles;
        } else if (proportion < 0.3) {
            styles.opacity = proportion * 3.4;
            styles.paddingLeft = `${-9 + 30 * proportion}rem`;
            if (inTransformLevel < 0) {
                styles.opacity = 1;
                delete styles.paddingLeft;
            } else if (inTransformLevel === 0) {
                styles.opacity = 1;
            } else if (inTransformLevel === 1) {
                styles.transform = `scale(${1.02 - (1.02 - 1) * proportion * 10 / 3})`;
            } else if (inTransformLevel === 2) {
                styles.opacity = proportion * 5.4;
                styles.transform = `scale(${1.06 - (1.02 - 1) * proportion * 10})`;
            } else if (inTransformLevel === 3) {
                styles.opacity = proportion * 5.4;
                styles.transform = `scale(${1.10 - (1.01 - 1) * proportion * 33})`;
            } else if (inTransformLevel === 4) {
                styles.opacity = proportion * 5.4;
                styles.transform = `scale(${10.0 - (1.3 - 1) * proportion * 100})`;
            }
            return styles;
        } else if (proportion < (inTransformLevel < 0 ? 0.2 : 0.8)) {
            styles.opacity = 1;
            return styles;
        } else if (proportion < 1.3) {
            styles.opacity = (1.3 - proportion) * 3.3;
            if (outTransformLevel < 0) {
                styles.transform = `scale(${1.02 - (proportion * 0.1)})`;
            } else if (outTransformLevel === 1) {
                styles.transform = `scale(${1.16 - (proportion * 0.2)})`;
            } else if (outTransformLevel === 2) {
                styles.transform = `scale(${1.32 - (proportion * 0.4)})`;
            }
            return styles;
        } else {
            styles.opacity = 0;
            return styles;
        }
    }

    function interpolatorSmoothMoveHorizontally(proportion, direction="leftToRight", timing=0, afterEffect=true) {
        if (proportion < timing) {
            if (direction === "leftToRight") return { opacity: 0, transform: 'translateX(-3rem)' };
            else if (direction === "rightToLeft") return { opacity: 0, transform: 'translateX(3rem)' };
        } else if (proportion > 1) {
            if (afterEffect) {
                if (direction === "leftToRight") return { opacity: 0, transform: 'translateX(3rem)' };
                else if (direction === "rightToLeft") return { opacity: 0, transform: 'translateX(-3rem)' };
            } else {
                return { opacity: 0 };
            }
        } else {
            return {};
        }
    }

    function interpolatorSmoothScroll(proportion, height, viewportHeight, startProportion=0.2, endProportion = 1.3, scrollSpeedPC=1800, scrollSpeedMobile=2400) {
        let positionY = 0;
        if (startProportion <= proportion && proportion <= endProportion) {
            positionY -= (proportion - startProportion) * (viewportHeight >= 1024 ? scrollSpeedPC : scrollSpeedMobile);
        }
        let styles = { transform: `matrix(1, 0, 0, 1, 0, ${positionY})` };
        if (proportion > endProportion) {
            styles.opacity = 0;
        }
        if (process.env.NODE_ENV !== "production") {
            if (proportion >= -startProportion && proportion < endProportion) {
                console.log("proportion:"+proportion+", height:"+height+", viewportHeight:"+viewportHeight+", positionY:"+positionY+", height/2:"+(height / 2));
            }
        }
        return styles;
    }

    return {
        interpolatorSmoothScaleUpAndDown,
        interpolatorSmoothMoveHorizontally,
        interpolatorSmoothScroll,
    };
}
