import { useMemo, useState } from "react";
import StickyView from "../common/StickyView";
import ScrollContext from "../common/ScrollContext";
import "./full.scss";
import { getInterpolators } from "./interpolators";

export default function Full(props) {
  const [scrollingElement, setScrollingElement] = useState(null);

  const {
    interpolatorSmoothScaleUpAndDown,
    interpolatorSmoothMoveHorizontally,
    interpolatorSmoothScroll,
  } = useMemo(() => getInterpolators(), []);

  const viewportHeight = window.innerHeight;

  function scrollingElRef(ref) {
    setScrollingElement(ref);
  }

  function checkActive(proportion, lower, upper) {
    return proportion > lower && proportion < upper ? "active" : "";
  }

  const logoList = [
    [
      "main-brands/logo-a.p.c..svg",
      "main-brands/logo-acne-studios.svg",
      "main-brands/logo-ai-riders.svg",
      "main-brands/logo-alexander-mcqueen.svg",
      "main-brands/logo-ami.svg",
      "main-brands/logo-autry.svg",
      "main-brands/logo-balenciaga.svg",
      "main-brands/logo-bally.svg",
      "main-brands/logo-balmain.svg",
      "main-brands/logo-bottega-veneta.svg",
      "main-brands/logo-burberry.svg",
      "main-brands/logo-c.p.company.svg",
      "main-brands/logo-celine.svg",
      "main-brands/logo-chloe.svg",
      "main-brands/logo-christian-louboutin.svg",
      "main-brands/logo-comme-des-garcons.svg",
      "main-brands/logo-common-projects.svg",
      "main-brands/logo-dior.svg",
      "main-brands/logo-dsquared2.svg",
      "main-brands/logo-duvetica.svg",
      "main-brands/logo-fear-of-god.svg",
      "main-brands/logo-fendi-roma.svg",
      "main-brands/logo-ferragamo.svg",
      "main-brands/logo-gallery-dept..svg",
      "main-brands/logo-ganni.svg",
      "main-brands/logo-gfore.svg",
      "main-brands/logo-givenchy.svg",
      "main-brands/logo-golden-goose.svg",
      "main-brands/logo-gucci-with-symbol.svg",
      "main-brands/logo-gucci.svg",
      "main-brands/logo-isabel-marant.svg",
      "main-brands/logo-issey-miyake.svg",
      "main-brands/logo-j-lindeberg.svg",
    ],
    [
      "main-brands/logo-jacquemus.svg",
      "main-brands/logo-jil-sander.svg",
      "main-brands/logo-kenzo.svg",
      "main-brands/logo-lemaire.svg",
      "main-brands/logo-loewe.svg",
      "main-brands/logo-longchamp.svg",
      "main-brands/logo-loro-piana.svg",
      "main-brands/logo-maison-kitsune-paris.svg",
      "main-brands/logo-maison-margiela-paris.svg",
      "main-brands/logo-malbon-golf.svg",
      "main-brands/logo-mark-and-lona.svg",
      "main-brands/logo-marni.svg",
      "main-brands/logo-max-mara.svg",
      "main-brands/logo-miu-miu.svg",
      "main-brands/logo-moncler.svg",
      "main-brands/logo-moose-knuckles.svg",
      "main-brands/logo-msgm.svg",
      "main-brands/logo-off-white.svg",
      "main-brands/logo-out-legacy.svg",
      "main-brands/logo-parajumpers.svg",
      "main-brands/logo-paul-smith.svg",
      "main-brands/logo-philippe-model-paris.svg",
      "main-brands/logo-prada.svg",
      "main-brands/logo-roger-vivier.svg",
      "main-brands/logo-saint-laurent.svg",
      "main-brands/logo-stone-island.svg",
      "main-brands/logo-ten-c.svg",
      "main-brands/logo-thom-browne.new-york.svg",
      "main-brands/logo-tods.svg",
      "main-brands/logo-tom-ford.svg",
      "main-brands/logo-tory-burch.svg",
      "main-brands/logo-valentino.svg",
      "main-brands/logo-vivienne-westwood.svg",
      "main-brands/logo-wooyoungmi-paris.svg",
      "main-brands/logo-y-3.svg",
    ],
    [
      "new-brands/logo-banks-journal.svg",
      "new-brands/logo-cherry-la.svg",
      "new-brands/logo-devereux-golf.svg",
      "new-brands/logo-firstport-company-usa.svg",
      "new-brands/logo-good-news-london.svg",
      "new-brands/logo-guizio-new-york.svg",
      "new-brands/logo-harmony-paris.svg",
      "new-brands/logo-husbands-paris.svg",
      "new-brands/logo-nice-as-heck.svg",
      "new-brands/logo-ololo-paris.svg",
      "new-brands/logo-puttwell.svg",
      "new-brands/logo-viron-world-paris.svg",
    ],
  ];

  function makeBrandLogoSection(
    wrapperClassName,
    logoSetNumber,
    proportion,
    direction = "leftToRight",
    timing = 0,
    afterEffect = true
  ) {
    function insertLogo(logoListSub, offset) {
      return [...Array(3).keys()].map((_, index) => (
        <div
          key={"brand-logo-" + offset + index}
          className="brand-logo"
          style={
            logoListSub[offset + index]
              ? {
                  backgroundImage:
                    "url(" +
                    process.env.PUBLIC_URL +
                    "/images/" +
                    logoListSub[offset + index] +
                    ")",
                }
              : {}
          }
        />
      ));
    }

    function insertLogoSectionRow(logoListSub) {
      let result = [];
      for (let j = 0; j < logoListSub.length; j += 3) {
        result.push(
          <div key={"brand-logo-row-" + j} className="brand-logo-row">
            {insertLogo(logoListSub, j)}
          </div>
        );
      }
      return result;
    }

    return (
      <div
        className={wrapperClassName}
        style={interpolatorSmoothMoveHorizontally(
          proportion,
          direction,
          timing,
          afterEffect
        )}
      >
        <div className="brand-logo-section">
          {insertLogoSectionRow(logoList[logoSetNumber])}
        </div>
      </div>
    );
  }

  return (
    <ScrollContext.Provider value={{ scrollingElement }}>
      <div className="scrolling-view" ref={scrollingElRef}>
        <StickyView
          name="sticky-1"
          height={viewportHeight >= 767 ? 3000 : 1500}
        >
          {(proportion) => (
            <div
              className={"full-sticky-content " + checkActive(proportion, 0, 1)}
            >
              <div id="full-directions" className="full-directions">
                <div className="arrow"></div>
                <div className="arrow"></div>
              </div>
              <div className="full-sticky-foreground">
                <div
                  className="content-subsection"
                  style={interpolatorSmoothScaleUpAndDown(proportion, -1, -1)}
                >
                  <h1>Introduction</h1>
                  <h2>
                    We Present
                    <br />
                    The Most Valuable Products
                    <br />
                    In The World
                    <br />
                    To Our Customers
                    <br />
                    In An Efficient Way.
                  </h2>
                  <h4>Through The Global Luxury Fashion Platform.</h4>
                </div>
                <div
                  className="content-background"
                  style={interpolatorSmoothMoveHorizontally(
                    proportion,
                    "leftToRight",
                    0.0
                  )}
                />
              </div>
              <div
                className="full-sticky-background"
                style={{
                  height: scrollingElement?.clientHeight,
                  ...interpolatorSmoothScaleUpAndDown(proportion, -1, 0),
                }}
              />
            </div>
          )}
        </StickyView>
        <StickyView
          name="sticky-2"
          height={viewportHeight >= 767 ? 3000 : 1500}
        >
          {(proportion) => (
            <div
              className={"full-sticky-content " + checkActive(proportion, 0, 1)}
            >
              <div className="full-sticky-foreground">
                <div className="content-text">
                  <div
                    className="content-subsection"
                    style={interpolatorSmoothMoveHorizontally(
                      proportion,
                      "leftToRight",
                      -0.2
                    )}
                  >
                    <h4>
                      "We strive
                      <br />
                      to provide a global environment
                      <br />
                      where the world's best
                      <br />
                      luxury fashion products
                      <br />
                      can be traded
                      <br />
                      quickly, easily, and safely."
                    </h4>
                  </div>
                  <div
                    className="content-background"
                    style={interpolatorSmoothMoveHorizontally(
                      proportion,
                      "leftToRight",
                      0.2
                    )}
                  />
                  <div
                    className="content-background"
                    style={interpolatorSmoothScaleUpAndDown(proportion, 3, 1)}
                  />
                </div>
                <div className="content-text">
                  <div
                    className="content-subsection"
                    style={interpolatorSmoothMoveHorizontally(
                      proportion,
                      "leftToRight",
                      0.2
                    )}
                  >
                    <h4>
                      "In addition,
                      <br />
                      we offer
                      <br />
                      real-time market analysis data
                      <br />
                      to help participants
                      <br />
                      in the luxury fashion industry
                      <br />
                      make more
                      <br />
                      rational decisions.
                    </h4>
                  </div>
                  <div
                    className="content-background"
                    style={interpolatorSmoothMoveHorizontally(
                      proportion,
                      "leftToRight",
                      0.35
                    )}
                  />
                  <div
                    className="content-background"
                    style={interpolatorSmoothMoveHorizontally(
                      proportion,
                      "leftToRight",
                      0.2
                    )}
                  />
                  <div
                    className="content-background"
                    style={interpolatorSmoothMoveHorizontally(
                      proportion,
                      "leftToRight",
                      0.2
                    )}
                  />
                  <div
                    className="content-background"
                    style={interpolatorSmoothMoveHorizontally(
                      proportion,
                      "leftToRight",
                      0.2
                    )}
                  />
                  <div
                    className="content-background"
                    style={interpolatorSmoothMoveHorizontally(
                      proportion,
                      "leftToRight",
                      0.2
                    )}
                  />
                </div>
              </div>
              <div
                className="full-sticky-background"
                style={{
                  height: scrollingElement?.clientHeight,
                  ...interpolatorSmoothScaleUpAndDown(proportion, 3, 0),
                }}
              />
            </div>
          )}
        </StickyView>
        {/* 20230602 B2B 서비스이기 때문에 멤버를 공개할 필요 없을 것이며 부담스러운 정보이기 때문에 제외. <StickyView name="sticky-3" height={3200} viewportHeight={viewportHeight}>
                    {(proportion, height, viewportHeight) => (
                        <div className={"full-sticky-content " + checkActive(proportion, 0, 1)}>
                            <div className="full-sticky-foreground" style={interpolatorSmoothScroll(proportion, height, viewportHeight, 0.2, 1.3, 1800, 2800)}>
                                <div className="content-section">
                                    <div className="content-text content-text1">
                                        <div className="content-subsection" style={interpolatorSmoothMoveHorizontally(proportion, "leftToRight", 0, false)}>
                                            <h5>CHANGSUN BYUN / PAOLO</h5>
                                            <h6>CEO</h6>
                                            <h6>FOUNDER</h6>
                                            <div className="content-text-wrap">
                                                <p><span>A Luxury B2C Platform, BALAAN</span><span>The General Director of the Partnership</span></p>
                                                <p><span>A Luxury Trading Agency, UNO Trading</span><span>CEO</span></p>
                                                <p><span>A Luxury Offline Shop, UNO</span><span>CEO</span></p>
                                                <p><span>The General Director of the Partnership</span><span>CEO</span></p>
                                            </div>
                                        </div>
                                        <div className="content-background" style={interpolatorSmoothMoveHorizontally(proportion, "leftToRight", -0.2, false)} />
                                    </div>
                                    <div className="content-text content-text2">
                                        <div className="content-subsection" style={interpolatorSmoothMoveHorizontally(proportion, "rightToLeft", 0.15, false)}>
                                            <h5>JEONGHUN BAEK / LUCA</h5>
                                            <h6>EXECUTIVE VICE PRESIDENT / CFO</h6>
                                            <h6>FINANCE / CORPORATE PARTNERSHIP</h6>
                                            <div className="content-text-wrap">
                                                <p><span>PeopleFund Companay, Institutional Investment Group</span><span>Team Leader</span></p>
                                                <p><span>DB Financial Investment</span><span>Overseas Bond Sales & Trading Domestic Bond Sales</span></p>
                                                <p><span>LIG Investment & Securities Co., Ltd</span><span>Domestic Bond Sales</span></p>
                                                <p><span>Eugene Investment & Securities Co.Ltd</span><span>Domestic Bond Sales</span></p>
                                                <p><span>Seoul National University</span><span>Bachelor of Agricultural Economics</span></p>
                                            </div>
                                        </div>
                                        <div className="content-background" style={interpolatorSmoothMoveHorizontally(proportion, "rightToLeft", 0.0, false)} />
                                    </div>
                                    <div className="content-text content-text3">
                                        <div className="content-subsection" style={interpolatorSmoothMoveHorizontally(proportion, "leftToRight", 0.3, false)}>
                                            <h5>YONGKYUN KIM / DIEGO</h5>
                                            <h6>EXECUTIVE VICE PRESIDENT / CSO</h6>
                                            <h6>STRATEGY / BUSINESS PLANNING</h6>
                                            <div className="content-text-wrap">
                                                <p><span>Influencer-based Fashion Platform, Reket</span><span>Vice President</span></p>
                                                <p><span>A Luxury B2C Platform, BALAAN</span><span>CSO</span></p>
                                                <p><span>Phoenix Games, Publishing Team</span><span>Team Leader</span></p>
                                                <p><span>Korea University</span><span>Bachelor of Arts in Law</span></p>
                                            </div>
                                        </div>
                                        <div className="content-background" style={interpolatorSmoothMoveHorizontally(proportion, "leftToRight", 0.2, false)} />
                                    </div>
                                    <div className="content-text content-text4">
                                        <div className="content-subsection" style={interpolatorSmoothMoveHorizontally(proportion, "rightToLeft", 0.45, false)}>
                                            <h5>HEEWON LIM / ANGELA</h5>
                                            <h6>EXECUTIVE DIRECTOR</h6>
                                            <h6>OVERSEAS BUSINESS DEPARTMENT</h6>
                                            <div className="content-text-wrap">
                                                <p><span>European Luxury Brand Sourcing</span><span>General Manager</span></p>
                                                <p><span>UNO Trading</span><span>Founding Member</span></p>
                                                <p><span>The G8 Summit</span><span>Simultaneous Interpreters</span></p>
                                                <p><span>National University of Rome</span><span>Bachelor of Arts in Costume</span></p>
                                            </div>
                                        </div>
                                        <div className="content-background" style={interpolatorSmoothMoveHorizontally(proportion, "rightToLeft", 0.35, false)} />
                                    </div>
                                    <div className="content-text content-text5">
                                        <div className="content-subsection" style={interpolatorSmoothMoveHorizontally(proportion, "leftToRight", 0.6, false)}>
                                            <h5>SEOMGI HAN / MENDINI</h5>
                                            <h6>CTO / CPO</h6>
                                            <h6>ENGINEERING / PRODUCT</h6>
                                            <div className="content-text-wrap">
                                                <p><span>PeopleFund Company, Product Division</span><span>CTO</span></p>
                                                <p><span>Korea Financial Telecommunications and Clearings Institute</span><span>Award certificate, 2021</span></p>
                                                <p><span>NHN, Mobile Payment PAYCO</span><span>Software Engineer</span></p>
                                                <p><span>Sungkyunkwan University</span><span>Bachelor of Arts in Visual Communication Design</span></p>
                                            </div>
                                        </div>
                                        <div className="content-background" style={interpolatorSmoothMoveHorizontally(proportion, "leftToRight", 0.5, false)} />
                                    </div>
                                </div>
                            </div>
                            <div className="full-sticky-background" style={{height: scrollingElement?.clientHeight, ...interpolatorSmoothScaleUpAndDown(proportion, 3, 0)}} />
                        </div>
                    )}
                </StickyView> */}
        <StickyView
          name="sticky-4"
          height={4200}
          viewportHeight={viewportHeight}
        >
          {(proportion, height, viewportHeight) => (
            <div
              className={"full-sticky-content " + checkActive(proportion, 0, 1)}
            >
              <div
                className="full-sticky-foreground"
                style={interpolatorSmoothScroll(
                  proportion,
                  height,
                  viewportHeight,
                  0.2,
                  1.3,
                  1200,
                  2000
                )}
              >
                <div className="content-section content-section1">
                  <div
                    className="content-text"
                    style={interpolatorSmoothMoveHorizontally(
                      proportion,
                      "leftToRight",
                      0,
                      false
                    )}
                  >
                    <h4>
                      We have established partnerships
                      <br />
                      with 62 luxury boutiques
                      <br />
                      and have been doing wholesale business
                      <br />
                      for over 10 years.
                    </h4>
                  </div>
                  {makeBrandLogoSection(
                    "content-text",
                    0,
                    proportion,
                    "leftToRight",
                    0.2,
                    false
                  )}
                  <div
                    className="content-background"
                    style={interpolatorSmoothMoveHorizontally(
                      proportion,
                      "leftToRight",
                      -0.2,
                      false
                    )}
                  />
                </div>
                <div className="content-section content-section2">
                  <div
                    className="content-text"
                    style={interpolatorSmoothMoveHorizontally(
                      proportion,
                      "leftToRight",
                      0.3,
                      false
                    )}
                  >
                    <h4>
                      Since 2018,
                      <br />
                      we achieved USD 37M
                      <br />
                      in transaction volume
                      <br />
                      by sourcing luxury products ordered
                      <br />
                      by parallel importers.
                    </h4>
                  </div>
                  {makeBrandLogoSection(
                    "content-text",
                    1,
                    proportion,
                    "leftToRight",
                    0.5,
                    false
                  )}
                  <div
                    className="content-background"
                    style={interpolatorSmoothMoveHorizontally(
                      proportion,
                      "leftToRight",
                      0.1,
                      false
                    )}
                  />
                </div>
              </div>
              <div
                className="full-sticky-background"
                style={{
                  height: scrollingElement?.clientHeight,
                  ...interpolatorSmoothScaleUpAndDown(proportion, 3, 0),
                }}
              />
            </div>
          )}
        </StickyView>
        <StickyView name="sticky-5" height={2800}>
          {(proportion) => (
            <div
              className={"full-sticky-content " + checkActive(proportion, 0, 1)}
            >
              <div className="full-sticky-foreground">
                <div className="content-section">
                  <div
                    className="content-text1"
                    style={interpolatorSmoothMoveHorizontally(proportion)}
                  >
                    <h5>
                      In 2023,
                      <br />
                      we formed an investment union
                      <br />
                      with 10 top tier
                      <br />
                      luxury wholesalers and retailers
                      <br />
                      in Korea.
                    </h5>
                  </div>
                </div>
                <div className="full-sticky-foreground-text">
                  <div className="content-section">
                    <div className="content-subsection">
                      <div
                        className="content-text2"
                        style={interpolatorSmoothMoveHorizontally(
                          proportion,
                          "rightToLeft"
                        )}
                      >
                        <h6>
                          Seoul:
                          <br />
                          Incheon: <br />
                          Daejeon: <br />
                          Daegu: <br />
                          Busan:{" "}
                        </h6>
                      </div>
                      <div
                        className="content-text3"
                        style={interpolatorSmoothMoveHorizontally(
                          proportion,
                          "rightToLeft"
                        )}
                      >
                        <h6>
                          5 companies (USD 105 million*)
                          <br />
                          1 company (USD 14 million*)
                          <br />
                          1 company (USD 12 million*)
                          <br />
                          2 companies (USD 52 million*)
                          <br />1 company (USD 48 million*)
                        </h6>
                      </div>
                    </div>
                    <div
                      className="content-text4"
                      style={interpolatorSmoothMoveHorizontally(
                        proportion,
                        "rightToLeft"
                      )}
                    >
                      <p>* Annual Sales in 2022</p>
                    </div>
                  </div>
                </div>
                <div
                  className="full-sticky-background-element-map"
                  style={interpolatorSmoothScaleUpAndDown(proportion, 2, 2)}
                />
                <div
                  className="full-sticky-background-element-guide"
                  style={interpolatorSmoothScaleUpAndDown(proportion, 2, 2)}
                />
              </div>
              <div
                className="full-sticky-background"
                style={{
                  height: scrollingElement?.clientHeight,
                  ...interpolatorSmoothScaleUpAndDown(proportion, 3, 0),
                }}
              />
            </div>
          )}
        </StickyView>
        <StickyView
          name="sticky-6"
          height={2200}
          viewportHeight={viewportHeight}
        >
          {(proportion, height, viewportHeight) => (
            <div
              className={"full-sticky-content " + checkActive(proportion, 0, 1)}
            >
              <div
                className="full-sticky-foreground"
                style={
                  900 > viewportHeight
                    ? interpolatorSmoothScroll(
                        proportion,
                        height,
                        viewportHeight,
                        0.4,
                        1.5,
                        1400,
                        1600
                      )
                    : {}
                }
              >
                <div className="content-section">
                  <div
                    className="content-text1"
                    style={interpolatorSmoothMoveHorizontally(proportion)}
                  >
                    <h5>
                      We introduce luxury goods using IT technology.
                      <br />
                      Presently, we are building a B2B platform
                      <br />
                      for innovative digital transformation
                      <br />
                      with the goal of launching it within the year 2023.
                      <br />
                      So that local sellers and agencies
                      <br />
                      can collaborate efficiently.
                    </h5>
                  </div>
                  <div
                    className="content-background"
                    style={interpolatorSmoothMoveHorizontally(
                      proportion,
                      "leftToRight",
                      -0.1
                    )}
                  />
                </div>
                <div className="content-section">
                  <div
                    className="content-text2"
                    style={interpolatorSmoothMoveHorizontally(
                      proportion,
                      "leftToRight",
                      0.2
                    )}
                  >
                    <h5>Technology in the Luxury Fashion B2B Platform</h5>
                  </div>
                  <div className="content-subsection">
                    <div
                      className="content-text3"
                      style={interpolatorSmoothMoveHorizontally(
                        proportion,
                        "leftToRight",
                        0.3
                      )}
                    >
                      <h6>Unstructured Data Standardization</h6>
                      <p>
                        Multi Data Format Converting Technology
                        <br />
                        : Transform and store the source data
                        <br />
                        of the luxury boutiques.
                      </p>
                      <p>
                        Image Data Linkage Technology
                        <br />
                        : Real-time storage
                        <br />
                        of tens of millions of data points.
                      </p>
                    </div>
                    <div
                      className="content-text4"
                      style={interpolatorSmoothMoveHorizontally(
                        proportion,
                        "leftToRight",
                        0.45
                      )}
                    >
                      <h6>Real-time Order Optimization</h6>
                      <p>
                        Multi-Data Search Engine Technology
                        <br />
                        : Search engine technology
                        <br />
                        for fashion-specific orders.
                      </p>
                      <p>
                        Condition Value-based Matching Technology
                        <br />
                        : Consolidation and matching
                        <br />
                        of orders according to trade conditions.
                      </p>
                    </div>
                    <div
                      className="content-text5"
                      style={interpolatorSmoothMoveHorizontally(
                        proportion,
                        "leftToRight",
                        0.6
                      )}
                    >
                      <h6>Luxury Inventory Valuation Model Design</h6>
                      <p>
                        Coefficient-based Value Evaluation Model
                        <br />
                        : Brand, season, distribution channel, credit base.
                        <br />
                        for fashion-specific orders.
                      </p>
                      <p>
                        Mass Inventory Data Interlocking Technology
                        <br />: Real-time inventory data linkage.
                      </p>
                    </div>
                  </div>
                  <div
                    className="content-background"
                    style={interpolatorSmoothMoveHorizontally(
                      proportion,
                      "leftToRight",
                      0.25
                    )}
                  />
                </div>
              </div>
              <div
                className="full-sticky-background"
                style={{
                  height: scrollingElement?.clientHeight,
                  ...interpolatorSmoothScaleUpAndDown(proportion, 3, 0),
                }}
              />
            </div>
          )}
        </StickyView>
        <StickyView
          name="sticky-7"
          height={2240}
          viewportHeight={viewportHeight}
        >
          {(proportion, height, viewportHeight) => (
            <div
              className={"full-sticky-content " + checkActive(proportion, 0, 1)}
            >
              <div
                className="full-sticky-foreground"
                style={
                  900 > viewportHeight
                    ? interpolatorSmoothScroll(
                        proportion,
                        height,
                        viewportHeight,
                        0.3,
                        1.3,
                        1800,
                        1600
                      )
                    : {}
                }
              >
                <div className="content-section">
                  <div
                    className="content-text1"
                    style={interpolatorSmoothMoveHorizontally(
                      proportion,
                      "leftToRight",
                      0.0,
                      false
                    )}
                  >
                    <h4>
                      As a luxury trading platform,
                      <br />
                      we diversify our fields
                      <br />
                      by continuously
                      <br />
                      discovering designer brands
                      <br />
                      and fulfilling our role
                      <br />
                      by supporting import and export.
                    </h4>
                  </div>
                  <div
                    className="content-background"
                    style={interpolatorSmoothMoveHorizontally(
                      proportion,
                      "leftToRight",
                      0.1,
                      false
                    )}
                  />
                </div>
                <div className="content-section">
                  <div
                    className="content-text2"
                    style={interpolatorSmoothMoveHorizontally(
                      proportion,
                      "leftToRight",
                      0.1,
                      false
                    )}
                  >
                    <h4>
                      Currently,
                      <br />
                      12 new global brands
                      <br />
                      are collaborating.
                    </h4>
                  </div>
                  {makeBrandLogoSection(
                    "content-text3",
                    2,
                    proportion,
                    "leftToRight",
                    0.4,
                    false
                  )}
                  <div
                    className="content-background"
                    style={interpolatorSmoothMoveHorizontally(
                      proportion,
                      "leftToRight",
                      0.3,
                      false
                    )}
                  />
                  <div
                    className="content-background"
                    style={interpolatorSmoothScaleUpAndDown(proportion, 2, 1)}
                  />
                </div>
              </div>
              <div
                className="full-sticky-background"
                style={{
                  height: scrollingElement?.clientHeight,
                  ...interpolatorSmoothScaleUpAndDown(proportion, 3, 0),
                }}
              />
            </div>
          )}
        </StickyView>
        <StickyView name="sticky-8" height={2400}>
          {(proportion) => (
            <div
              className={"full-sticky-content " + checkActive(proportion, 0, 1)}
            >
              <div className="full-sticky-foreground">
                <address>
                  <div
                    className="content-text1"
                    style={interpolatorSmoothMoveHorizontally(
                      proportion,
                      "leftToRight",
                      0,
                      false
                    )}
                  >
                    <h6>uno@unotrading.co.kr</h6>
                    <h6>
                      <a href={process.env.PUBLIC_URL + "/#full-directions"}>
                        www.unotrading.co.kr
                      </a>
                    </h6>
                    <h6>
                      <a href="https://pathrade.com?utm_source=uno-homepage&utm_medium=homepage&utm_campaign=app-contack-link&utm_content=text-link">
                        pathrade.com
                      </a>
                    </h6>
                    <h6>
                      <a href="https://blog.pathrade.com?utm_source=uno-homepage&utm_medium=homepage&utm_campaign=blog-contack-link&utm_content=text-link">
                        blog.pathrade.com
                      </a>
                    </h6>
                  </div>
                  <div
                    className="content-text2"
                    style={interpolatorSmoothMoveHorizontally(
                      proportion,
                      "rightToLeft",
                      0,
                      false
                    )}
                  >
                    <h6>
                      5FL, 464, Gangnam-daero, Gangnam-gu,
                      <br />
                      Seoul, Republic of Korea (06241)
                    </h6>
                  </div>
                </address>
              </div>
              <div className="full-sticky-background-element">
                <div
                  className="content-background1"
                  style={interpolatorSmoothMoveHorizontally(
                    proportion,
                    "rightToLeft",
                    0.3,
                    false
                  )}
                />
                <div
                  className="content-background2"
                  style={interpolatorSmoothScaleUpAndDown(proportion, 2, 0)}
                />
                <div className="content-background3" />
              </div>
              <div
                className="full-sticky-background"
                style={{
                  height: scrollingElement?.clientHeight,
                  ...interpolatorSmoothScaleUpAndDown(proportion, 3, 0),
                }}
              />
            </div>
          )}
        </StickyView>
      </div>
    </ScrollContext.Provider>
  );
}
